.page .page__body.help-support .page__section .page__subsection {
    grid-gap: var(--space-md);
    gap: var(--space-md);
}

.tutorial-video {
    justify-content: center;
    iframe {
        max-width: 100%;
    }
}

.host-best-practices ul {
    margin-left: 1rem;

    li {
        display: flex;
        margin-bottom: 1rem;
        svg {
            position: relative;
            top: 5px;
            margin-right: 1rem;
        }
    }
}
.ul-statement-of-faith p {
    font-size: 1.2rem;
}

@media only screen and (max-width: 768px) {
    .page.help-page {
        padding-left: 0;
        padding-right: 0;

        .page__title, .page__subsection {
            padding: 0 2rem;
        }

        h2 {
            text-align: center;
        }

        .host-best-practices ul {
            margin-left: 0;
        }

        .faq .page__subsection {
            padding: 0;
            > h2 {
                padding: 0 2rem;
            }
            > div {
                margin: 0;
            }
            p {
                margin-right: 1rem;
            }
        }
    }
}