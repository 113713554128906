:root {
  // colors
  [data-theme-region="uz"] {
    --ui-background: #c3f3eb;
    --primary-dark: #045d4d;
    --primary-light: #15ac92; //theme
    --text-default: #045d4d;
    --text-dark: #045d4d;
    --graph-bar-dark: #1f356e;
    --text-link: #15ac92; //theme
    --secondary-default: #fda533;
    --secondary-dark: #f29513;
    --ui-gray-lighter: #f7faff;
    --ui-gray: #ebeef5;
    --ui-gray-dark: #02715f; //theme
    --ui-gray-darker: #dadde2;
    --ui-blue: #e5edff;
    --ui-blue-dark: #dde4f7;
    --table-line: rgba(31, 53, 110, 0.2);
    --metric: #3251a6;
    &[data-theme="dark"] {
      --ui-background: #077561;
      --primary-dark: #02715f;
      --primary-light: #15ac92; //theme
      --graph-bar-dark: #003d32;
      --text-default: #ffffff;
      --text-dark: #003d32;
      --text-link: #15ac92; //theme
      --secondary-default: #82c346;
      --secondary-dark: #82c346;
      --ui-gray-lighter: #045d4d;
      --ui-gray: #f7faff;
      --ui-gray-dark: #02715f; //theme
      --ui-gray-darker: #dadde2;
      --ui-blue: #f7faff;
      --ui-blue-dark: #16264e;
      --table-line: rgba(31, 53, 110, 1);
    }
  }
}
