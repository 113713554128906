.spinner-wrapper {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  p {
    margin-top: 15px;
    color: var(--grey);
  }
  .spinner {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 10px solid var(--grey);
    border-top: 10px solid var(--bg-dark);
    animation: spin 2s cubic-bezier(0.680, -0.550, 0.265, 1.550) infinite;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}