.seeker-profile-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--space-md) var(--space-sm);
  grid-area: info;

  .seeker-profile-info-row {
    margin: var(--space-xs) 0;
    .seeker-profile-info-row--title {
      font-size: 0.75rem;
      opacity: 0.5;
    }

    .seeker-profile-info-row--value {
      margin: 0;
      font-size: 1rem;
    }
  }

  .click-to-connect {
    border-style: none;
    background-color: var(--ui-background);
    color: var(--text-default);
    border-radius: 8px;
    transition: 0.2s;
    cursor: pointer;
    width: 100%;
    padding: var(--space-sm);
    font-weight: bold;
    margin: var(--space-sm) 0;

    &:hover {
      filter: brightness(0.9);
    }
  }
}

@media only screen and (max-width: 1024px) {
  .seeker-profile-info {
    grid-area: data;
    padding: var(--space-xs);

    &.mobile-active {
      display: flex;
    }
  }
}
