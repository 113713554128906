.breadcrumbs {
  display: flex;
  align-items: center;
  .breadcrumbs__link {
    margin-right: .5em;
    color: var(--text-default);
    &:is(a):hover {
      text-decoration: underline;
    }
  }
}
