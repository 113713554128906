* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  width: 100%;
  font-family: "Open Sans", sans-serif !important;
}

.main {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  color: var(--text);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    background-color: var(--bg-dark);
    height: 12vh;
    padding: 30px 20px;

    .logo-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      img {
        width: 200px;
        transition: 0.2s;

        &:hover {
          opacity: 0.75;
        }
      }
    }

    .page-title {
      text-align: center;
      margin: 0;
      font-weight: normal;
    }

    .host-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .dropdown {
        padding: 10px;
        font-size: 14px;
        margin-right: 15px;
        transition: 0.2s;
        position: relative;
        cursor: pointer;

        .icon {
          margin-left: 10px;
        }

        &.open {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }

        &:hover {
          background-color: var(--grey-opaque);
        }

        span {
          transition: 0.2s;
          font-size: 16px;
          color: var(--highlight);
          font-weight: bold;
          margin-right: 5px;
        }
      }
    }
  }

  .title {
    display: flex;
    justify-content: center;
    min-height: 75px;
    height: auto;
    max-width: 1000px;
    margin: 0px auto;
    text-align: center;

    h3 {
      margin: auto;
    }
  }

  .page {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-image: linear-gradient(
      to right,
      var(--gradient-light),
      var(--gradient-dark)
    );
  }
}
@media only screen and (max-width: 1200px) {
  .main .header {
    .logo-wrapper img {
      width: 100px;
    }
    .page-title {
      font-size: 1.25rem;
    }
  }
}

@media only screen and (max-width: 992px) {
  .main .header {
    .page-title {
      font-size: 1rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main {
    .header {
      padding: 10px 5px;
      height: 10vh;
      grid-template-columns: 1fr 1fr;

      .logo-wrapper img {
        margin-left: 15px;
      }

      .page {
        flex: 0;
        height: 90vh;
      }

      .page-title {
        display: none;
      }

      .host-info {
        .dropdown {
          padding: 5px 15px;
          font-size: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            font-size: 14px;
            margin: 0;
            font-weight: normal;
          }
        }
      }
    }
  }
}
