@import 'config';
.shepherd-modal-overlay-container {
    height: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: all .3s ease-out,height 0ms .3s,opacity .3s 0ms;
    width: 100vw;
    z-index: 9997;
    &.shepherd-modal-is-visible {
        height: 100vh;
        opacity: 0.5;
        transition: all .3s ease-out,height 0s 0s,opacity .3s 0s;
    }
}
#tour-start-button {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}
.shepherd-element {
    background: white;
    padding: 1rem;
    max-width: 30rem;
    border-radius: 1rem;
    box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
    outline: none;
    transition: opacity .3s,visibility .3s;
    z-index: 9999;

    .shepherd-text {
        margin-bottom: 1rem;
    }
    .shepherd-button-primary {
        color: #fff;
        font-size: 1.2em;
        background-color: var(--primary-light);
        border: 2px solid var(--primary-light);
        border-radius: var(--border-radius-sm);
        box-shadow: var(--box-shadow-sm);
        padding: 0.2rem 0.5rem;
        margin-right: 0.5rem;
        cursor: pointer;
        &.prev::after {
            content: "\2190";
        }
        &.next::after {
            content: "\2192";
        }
    }

    &.final-slide.cta .shepherd-text {
        font-style: italic;
    }
}

@media only screen and (max-width: 768px) {
    .shepherd-element {
        max-width: none;
        width: 100%;
      }
}
