:root {
  // colors
  [data-theme-region="us"] {
    --ui-background: #ebeef7;
    --primary-dark: #1f356e;
    --primary-light: #2957cc;
    --text-default: #1f356e;
    --text-dark: #1f356e;
    --graph-bar-dark: #1f356e;
    --text-link: #1448cc;
    --secondary-default: #fda533;
    --secondary-dark: #f29513;
    --ui-gray-lighter: #f7faff;
    --ui-gray: #ebeef5;
    --ui-gray-dark: #d7ddea;
    --ui-gray-darker: #dadde2;
    --ui-blue: #e5edff;
    --ui-blue-dark: #dde4f7;
    --table-line: rgba(31, 53, 110, 0.2);
    --metric: #3251a6;
    &[data-theme="dark"] {
      --ui-background: #192c59;
      --primary-dark: #1f356e;
      --primary-light: #2662f9;
      --text-default: #ffffff;
      --text-dark: #1f356e;
      --text-link: #fd940a;
      --secondary-default: #fd940a;
      --secondary-dark: #f29513;
      --ui-gray-lighter: #2a468c;
      --ui-gray: #1f366b;
      --ui-gray-dark: #192c59;
      --ui-gray-darker: #dadde2;
      --ui-blue: #192c59;
      --ui-blue-dark: #16264e;
      --ui-alert: #c92e24;
      --ui-alert-dark: #c90c00;
    }
  }
}
