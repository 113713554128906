.navigation {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  background-color: rgba(#f3f3f3, 0.75);

  p {
    display: inline-block;
    padding: 15px 50px;
    transition: 0.2s all ease-in-out;
    color: var(--bg-dark);
    cursor: pointer;

    &:hover,
    &.current {
      background-color: var(--bg-dark);
      color: var(--text);
    }
  }
}

.queue {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .empty {
    margin: 35px;
    font-size: 24px;
    color: var(--grey);
    opacity: 0.5;
    text-align: center;
  }
}
.seeker-info-row--loading{
  grid-column: 1 / span 2;
}
.message-container {
  margin: 1em 0;
}

.load-more-btn {
  margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
  .navigation {
    width: 100%;
    p {
      padding: var(--space-sm) var(--space-md);
      text-align: center;
      font-size: 0.95rem;
    }
  }
  .queue {
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .navigation {
    width: 100%;
    p {
      padding: var(--space-xs) var(--space-sm);
      text-align: center;
      font-size: 0.85rem;
    }
  }
  .queue {
    width: 90%;

    .empty {
      font-size: 1.25rem;
    }
  }
}

@media only screen and (max-width: 360px) {
  .navigation {
    flex-direction: column;

    p {
      width: 100%;
    }
  }
}
.last-message{
  padding-top: 20px;
}
