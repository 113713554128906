:root {
  --gradient-light: #487AF9;
  --gradient-dark: #2C4B99;
  --bg-regular: #3458B2;
  --bg-dark: #2F4F9F;
  --text: white;
  --grey: #f3f3f3;
  --grey-opaque: rgba(#f3f3f3, .25);
  --highlight: #ffa231;
}

[color-scheme = "umid"] {
  --gradient-light: #8EBFBA;
  --gradient-dark: #6FA6A0;
  --bg-regular: #4D8C86;
  --bg-dark: #365956;
  --text: white;
  --grey: #f3f3f3;
  --grey-opaque: rgba(#f3f3f3, .25);
  --highlight: #A61C35;
}