.field {
  margin-bottom: 1rem;
}

.field-checkbox {
  padding-top: 12px;
  padding-bottom: 12px;
}

label {
  font-weight: 600;
  font-size: 1.6rem;
}


.label-checkbox {
  font-weight: 600 !important;
  font-size: 1rem !important;
}

form {
  width: 100%;
}

.input-checkbox-wrap {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

input {
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0.4em 2em 0.4em 1em;
  color: var(--text-default);
  letter-spacing: 1px;
  background: var(--ui-gray-lighter);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--ui-gray-lighter);

  &.input-text {
    &:focus-visible {
      outline: none;
    }
  }
  &[type=checkbox].input-checkbox {
    padding: 15px;
    background: var(--ui-gray-lighter);
    margin-top: 0;
    margin-right: 1rem;
    border-color: var(--text-default);
  }

  &.fieldDisabled {
    background: #d7dadd;
  }

  &.btn-save {
    margin-top: 20px;
    background-color: var(--primary-dark);
    color: #ffffff;
    font-size: 1.3rem;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      background-color: var(--primary-light);
      color: var(--primary-dark);
    }
  }
}

.tos-download {
  text-decoration: underline;
  color: var(--text-default);;
}

.span-error {
  font-weight: 700;
  display: block;
  padding: 15px;
  color: darkred;
}

select {
  width: 100%;
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--text-default);
  background: var(--ui-gray-lighter);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--ui-gray-lighter);
  display: block;
  -webkit-appearance: none;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline: none;
  }

  option {
    padding: 0;
    margin: 0;
    background: var(--ui-gray-dark);
    border: 1px solid var(--ui-gray-lighter);
    box-sizing: border-box;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 500;
    position: absolute;
    transform: translate(0, -15px);
    width: 100%;
    z-index: 100;
    border-radius: var(--border-radius-md);
  }

  // li {
  //   list-style: none;
  // margin-bottom: 0.8em;
  // padding: 0.8em 1em;
  //   &:hover {
  //     background: rgba(0, 0, 0, 0.3) !important;
  //     cursor: pointer;
  //   }
  // }
}

@media screen and (max-width: 768px) {
  .field-checkbox {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

// margin-bottom: 0.8em;
// padding: 0.4em 2em 0.4em 1em;
// box-shadow: 0 2px 3px rgba(0,0,0,0.15);
// font-weight: 500;
// font-size: 1.3rem;
// color: var(--text-default);
// background: var(--ui-gray-lighter);
// border-radius: var(--border-radius-md);
// border: 1px solid var(--ui-gray-lighter);
// border-radius: var(--border-radius-md);

// export const Main = styled("div")`
//   font-family: sans-serif;
//   background: var(--ui-gray-dark);
//   height: 100vh;
// `;

// export const DropDownContainer = styled("div")`
//   position: relative;
//   display: inline-block;
//   margin: 0 0.25em;
// `;

// export const DropDownHeader = styled("div")`
//   margin-bottom: 0.8em;
//   padding: 0.4em 2em 0.4em 1em;
//   box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
//   font-weight: 500;
//   font-size: 1.3rem;
//   color: var(--text-default);
//   background: var(--ui-gray-lighter);
//   border-radius: var(--border-radius-md);
//   border: 1px solid var(--ui-gray-lighter);

//   &:hover {
//     cursor: pointer;
//   }
//   border-radius: var(--border-radius-md);
// `;

// export const DropDownListContainer = styled("div")``;

// export const DropDownList = styled("ul")`
//   padding: 0;
//   margin: 0;
//   background: var(--ui-gray-dark);
//   border: 1px solid var(--ui-gray-lighter);
//   box-sizing: border-box;
//   color: var(--text-default);
//   font-size: 1.3rem;
//   font-weight: 500;
//   position: absolute;
//   transform: translate(0, -15px);
//   width: 100%;
//   z-index: 100;
//   border-radius: var(--border-radius-md);
// `;

// export const ListItem = styled("li")`
//   list-style: none;
//   margin-bottom: 0.8em;
//   padding: 0.8em 1em;
//   &:hover {
//     background: rgba(0, 0, 0, 0.3);
//     cursor: pointer;
//   }
//   &:last-of-type {
//     margin-bottom: 0;
//   }
//   border-radius: var(--border-radius-md);
// `;
