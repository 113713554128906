:root {
  // colors
  [data-theme-region="az"] {
    --ui-background: #ebeef7;
    --primary-dark: #70000a;
    --primary-light: #70000a; //theme
    --text-default: #70000a;
    --text-dark: #70000a;
    --graph-bar-dark: #70000a;
    --text-link: #70000a; //theme
    --secondary-default: #fda533;
    --secondary-dark: #f29513;
    --ui-gray-lighter: #cbc2c3;
    --ui-gray: #ebeef5;
    --ui-gray-dark: #d9d4d4; //theme
    --ui-gray-darker: #dadde2;
    --ui-blue: #e5edff;
    --ui-blue-dark: #dde4f7;
    --table-line: rgba(31, 53, 110, 0.2);
    --metric: #3251a6;
    &[data-theme="dark"] {
      --ui-background: #420005;
      --primary-dark: #420005;
      --primary-light: #6c373c; //theme
      --graph-bar-dark: #2662f9;
      --text-default: #ffffff;
      --text-dark: #ebeef7;
      --text-link: #ebeef7; //theme
      --secondary-default: #fd940a;
      --secondary-dark: #e28707;
      --ui-gray-lighter: #73343a;
      --ui-gray: #1f366b;
      --ui-gray-dark: #420005; //theme
      --ui-gray-darker: #dadde2;
      --ui-blue: #192c59;
      --ui-blue-dark: #16264e;
      --table-line: #420005;
    }
  }
}
