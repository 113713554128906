@import '../../../styles/v2/_config.scss';

.layout {
	min-height: 100vh;
	margin: 0 auto;
	display: block;

	@include respond-above(lg) {
		display: grid;
		grid-template-columns: 1fr 7fr;
		grid-template-rows: 7fr 160px;
		grid-template-areas:
			'sidebar page'
			'footer footer';
	}
}

.page {
	grid-area: page;
	width: 100%;
	max-width: var(--max-width);
	padding: var(--space-page) var(--space-md) var(--space-lg);
	min-height: 100vh;
	// margin-top: var(--space-md);
	@include respond-below(xs){
		padding-top: var(--space-sm) ;
		padding-bottom: var(--space-sm);
	}

	@include respond-above(xs) {
		padding-left: var(--space-md);
		padding-right: var(--space-md);
	}

	@include respond-above(sm) {
		padding: var(--space-page) var(--space-lg);
	}

	@include respond-above(lg) {
		padding: var(--space-xl) var(--space-xl) var(--space-page);
		margin-top: 0;
	}

	.page__header {
		grid-area: header;
		margin-bottom: var(--space-md);

		@include respond-above(xs) {
			margin-bottom: var(--space-lg);
		}

		.navigation {
			display: flex;
			align-items: center;
			margin-bottom: var(--space-sm);

			> * + * {
				margin-left: 0.75rem;
			}

			@include respond-above(xs) {
				margin-bottom: var(--space-md);
			}
		}
	}

	.page__body {
		display: grid;
		gap: var(--space-lg);
		@include respond-below(xs){
			 padding-top: var(--space-xl)
		}
		@include respond-below(xs) {
			gap: var(--space-md);
		}

		.page__title {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			h4 {
				position: relative;
				&:hover {
					cursor: pointer;
					color: var(--text-link);
				}
				.has_unread_messages {
					&.hide {
						display: none;
					}
					position: absolute;
					top: -9px;
					left: -6px;
					color: orange;
				}
			}

		}
		.page__section {
			display: grid;
			gap: var(--space-md);

			@include respond-above(xs) {
				gap: var(--space-lg);
			}


			@include respond-above(lg) {
				&.grid--1-1 {
					grid-template-columns: 1fr 1fr;
				}
				&.grid--2-1 {
					grid-template-columns: 2fr 1fr;
				}
				&.grid--1-2 {
					grid-template-columns: 1fr 2fr;
				}
				&.grid--1-1-1 {
					grid-template-columns: 1fr 1fr 1fr;
				}
			}

			.page__subsection {
				display: grid;
				gap: var(--space-md);
				//grid-auto-rows: minmax(min-content, max-content);

				@include respond-above(xs) {
					gap: var(--space-lg);
				}
			}
		}
	}
}
