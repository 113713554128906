.seeker-profile {
  width: 75%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  .seeker-not-found {
    text-align: left;
    height: 40rem;
    max-height: 100vh;
    padding-left: 10%;
    margin: 25vh auto;

  }
  .card {
    width: 100%;
    height: 75vh;
    border-radius: 15px;
    background-color: var(--bg-dark);
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "header messages"
      "info messages";
    column-gap: var(--space-md);
    padding: var(--space-md);
    box-shadow: 0px 0px 8px #00000020;

    .seeker-profile-header {
      display: flex;
      align-items: center;
      padding: 0 var(--space-sm) var(--space-sm);
      border-bottom: 2px solid #ffa231;

      .icon {
        transition: 0.2s;
        cursor: pointer;
        margin-right: var(--space-md);

        &:hover {
          filter: brightness(0.9);
        }
      }

      .toggle-icon {
        margin-left: var(--space-sm);
        display: none;
      }

      h2 {
        font-size: 1.75rem;
        position: relative;
        text-align: left;
        flex: 1;
      }
    }
  }
}
.messages-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100%;
  .messages-container {
    min-height: 10px;
    width: 100%;
  }
}
.message-input {
  input {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1024px) {
  .seeker-profile {
    width: 80%;

    .card {
      height: 80vh;
      grid-template-columns: 1fr;
      grid-template-areas:
        "header"
        "data";
      row-gap: var(--space-md);

      .seeker-profile-header {
        padding: 0 var(--space-xs) var(--space-sm);

        h2 {
          font-size: 1.25rem;
        }
        .icon {
          margin-right: var(--space-sm);
        }

        .toggle-icon {
          display: block;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .seeker-profile {
    width: 90%;
  }
}
