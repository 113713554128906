@import '../../styles/v2/_config.scss';

.overview_numbers {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  font-size: 4em;
  .overview_number_tile {
    padding: 0.25em;
    text-align: center;
    h1{padding-right: 0;}
    h4 {
      font-weight: bold;
      @include respond-below(xs) {
        white-space: nowrap;
        font-size: 0.775rem;
      }
    }
  }
}
.preview_message {
  font-size: 0.85em;
  background: var(--ui-gray-dark);
  padding: 1em;
  border-bottom: 1px solid var(--text-default);
  border-radius: var(--border-radius-md);
  color: var(--text-default);
  margin-bottom: 10px;
}
.title-flex {
  display: flex;
  flex-direction: row;
}
