@import "../../../../styles/v2/config";
.sidebar-wrapper {
  position: sticky;
  width: 100%;
  top: 0;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--ui-background);
  padding: 0;
  min-height: 100px;
}

div#root[data-theme="dark"] .sidebar .logo {
  filter: brightness(5);
}

.sidebar {
  background-color: var(--ui-background);
  position: fixed;
  display: flex;
  align-items: center;
  margin: 0;
  background-color: var(--ui-gray-lighter);
  grid-area: sidebar;
  justify-content: space-between;
  width: 100%;
  box-shadow: var(--box-shadow-sm);
  z-index: 2;
  @include respond-above(md) {
    // padding: var(--space-sm) var(--space-md);
  }
  @include respond-above(lg) {
    flex-direction: column;
    max-width: 300px;
    justify-content: inherit;
    // padding: var(--space-lg) var(--space-md);
    position: inherit;
    width: auto;
  }
  .logo {
    width: 180px;
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    @include respond-above(lg){
      margin: 0 auto;
    }
  }

  .page-links {
    display: block;
    height: 100%;
    @include respond-above(lg) {
      width: 100%;
      margin-top: var(--space-lg);
    }

    .menu-icon {
      position: fixed;
      transform: translateY(-50%);
      top: 2.5rem;
      right: 2rem;
      z-index: 2;

      @include respond-above(lg) {
        display: none;
      }
      &:hover {
        cursor: pointer;
      }
      .menu-icon__bar {
        width: 38px;
        height: 4px;
        margin-bottom: 7px;
        border-radius: var(--border-radius-md);
        background-color: var(--text-default);
        transition: 0.2s ease;
        &:last-of-type {
          margin-bottom: 0;
        }
        &.menu-icon__bar--1 {
          transform-origin: top left;
        }
        &.menu-icon__bar--1 {
          opacity: 1;
        }
        &.menu-icon__bar--3 {
          transform-origin: bottom left;
        }
      }

      &.active {
        .menu-icon__bar--1 {
          transform: rotate(45deg);
          width: 33px;
        }
        .menu-icon__bar--2 {
          transform: translateX(75%);
          opacity: 0;
        }
        .menu-icon__bar--3 {
          transform: rotate(-45deg);
          width: 33px;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: var(--space-xs);
      position: fixed;
      top: 0;
      right: 0%;
      transform: translateX(100%);
      transition: 0.3s ease;
      height: 100vh;
      background-color: var(--ui-gray-lighter);
      padding: var(--space-page) var(--space-md);
      box-shadow: var(--box-shadow-sm);

      &.active {
        transform: translateX(0);
      }

      @include respond-above(lg) {
        position: inherit;
        transform: translateX(0);
        width: 100%;
        height: inherit;
        background: none;
        padding: 0 10px;
        box-shadow: none;
      }

      a {
        text-decoration: none;
      }

      .page-link-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 0;
        padding-left: var(--space-md);
        padding-right: var(--space-md);
        border-radius: 100px;
        white-space: normal;

        &.btn-primary:hover {
          background-color: var(--primary-light);
        }

        &:not(.btn-primary):hover {
          background-color: var(--ui-gray-dark);
        }

        svg {
          width: 1.5rem;
          height: 1.25rem;
          margin-bottom: 2px;
          margin-right: 12px;
        }
      }

      .page-link {
        color: inherit;
        margin-bottom: 0;
        line-height: 1.3;
        text-align: left;
      }
    }
  }
}

.logout-link {
  text-align: center;
  margin-bottom: 2rem;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: 700;
    color: var(--text-link);
    font-size: 0.75rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

/*Simple css to style it like a toggle switch*/
.theme-switch-wrapper {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  span {
    display: inline-block;
    font-weight: 700;
    margin-bottom: var(--space-xs);
  }
}
.theme-switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 90px;
}

.theme-switch input {
  visibility: hidden;
}

.slider {
  background-color: rgb(200, 200, 200);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.2s ease;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 44px;
}

input:checked + .slider {
  background-color: var(--primary-dark);
}

input:checked + .slider:before {
  transform: translateX(38px);
}

.slider.round {
  border-radius: 26px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) inset;
}

.slider.round:before {
  border-radius: 26px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
