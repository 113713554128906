.page.password-reset-wrapper {
    grid-column: 1 / span 2;
    h2{
        line-height: 1.75rem;
        padding-bottom: 12px;
    }
}
.password-reset-card {
    max-width: 40rem;
    margin: 0 auto;
  }
.password-reset-box {
    padding: 0.5rem;
}
.password-reset {
    input {
        background: var(--ui-background);
    }
    .submit {
        border: none;
        cursor: pointer;
        border-radius: var(--border-radius-md);
        background-color: var(--text-default);
        padding: 10px 20px;
        color: white;
        font-size: 1rem;
        font-weight: bold;
        margin: 1rem 0;
        width: 100%;
        &:hover {
            background-color: var(--primary-light);
        }
    }
    .password-reset-disclaimer {
        font-size: 0.785rem;
    }
}

@media only screen and (max-width: 768px) {
.password-reset {
    width: 100%;
    .password-reset-box {
        width: 100%;
    }
}
}
